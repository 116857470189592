<!--
 * @Author: steven 1105624290@qq.com
 * @Date: 2022-07-21 10:02:33
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2022-07-27 16:02:15
 * @FilePath: /ytjj-mobile/src/views/pd/details/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="pd_details">
        <div class="header">
            <div class="plan_id">
                <van-icon @click="goback1" class="icon_top" name="arrow-left" />
                <!-- <div class="name1">{{ courseName }}</div> -->
                <div class="name1">详情</div>
                <div class="right" v-if="PDFData.length" @click="goPDF">
                    <img class="pdf_icon" src="@/assets/pd/Layer 25@2x.png" alt="">
                    <div class="text">附件</div>
                </div>
                <div class="no_pdf" v-else></div>
            </div>
        </div>
        <div class="body">
            <div class="content">
                <div class="top_header">
                    {{ courseName }}
                </div>
                <div class="title">
                    <div class="select">
                        <van-dropdown-menu :active-color="'#4EB8B8'">
                            <van-dropdown-item :title="title" @change="chooseItem" v-model="value" :options="companyList" />
                        </van-dropdown-menu>
                    </div>
                    <div class="all_num">
                        <div class="num">{{ currentPage }}/{{ companyList.length }}</div>
                        <div class="text">总章节</div>
                    </div>
                </div>
                <div class="small_name">
                    {{ currentPage }}. {{title}}
                </div>
                <div class="wangEditor ql-editor">
                    <div class="viewHeight" v-html="quillData.chapterContent"></div>
                </div>
            </div>
        </div>
        <div class="next_btn">
            <div class="footer" v-if="companyList.length == 1 && index + 1 === length">
                <van-button round class="btn" @click="goback">返回首页</van-button>
            </div>
            <div class="footer" v-else>
                <van-button round class="btn" :class="currentPage == 1 ? 'gray' : ''" @click="previous">上一页</van-button>
                <van-button round class="btn" v-if="currentPage >= companyList.length && index + 1 === length" @click="goback">返回首页</van-button>
                <van-button round class="btn" v-else-if="currentPage >= companyList.length" @click="nextClass">下一课</van-button>
                <van-button round class="btn" v-else @click="next">下一页</van-button>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { chapterListApi, pdListApi,coursePDF } from "@/api/pd/index";
    export default {
        data () {
            return {
                pdIndexData:[],
                index:0,
                length:0,
                loading:false,
                companyList: [],
                quillData: {}, //当前编辑器展示数据
                PDFList: [], //获取所有的PDF
                allQuillData: [], //全部编辑器的内容
                currentPage: 1,
                courseName: "", //课程名称
                value: "",
                PDFData:[],
                title:""
            }
        },
        methods:{
            // 获取所有的上传的pdf
            queryPDF() {
                let obj = {
                    courseId: this.$route.params.id,
                };
                coursePDF(obj).then((res) => {
                    this.PDFData = res.data;
                });
            },
            // 去pdf列表
            goPDF(){
                this.$router.push({
                    name: "pdfList",
                    params: {
                        id: this.$route.params.id,
                    },
                });
            },
            // 点击下一课
            nextClass() {
                this.$router.push({
                    name: "pdDetails",
                    params: {
                    id: this.pdIndexData[(this.index += 1)].id,
                    typeId: this.$route.params.typeId,
                    },
                });
                this.index += 1;
            },
            // 下一页
            next() {
                if (this.currentPage < this.companyList.length) {
                    this.quillData = this.allQuillData[this.currentPage];
                    this.value = this.allQuillData[this.currentPage].id;
                    this.title = this.allQuillData[this.currentPage].chapterName;
                    this.currentPage += 1;
                } else {
                    let a = this.currentPage;
                    this.currentPage = a;
                }
                document.documentElement && document.getElementsByClassName("content")[0].scrollTo(0,0);
            },
            // 上一页
            previous() {
                if (this.currentPage > 1) {
                    this.currentPage -= 1;
                    this.quillData = this.allQuillData[this.currentPage - 1];
                    this.value = this.allQuillData[this.currentPage - 1].id;
                    this.title = this.allQuillData[this.currentPage - 1].chapterName;
                } else {
                    this.currentPage = 1;
                }
            },
            // 选择下拉
            chooseItem(data) {
                console.log("sandaskndnrj",data)
                this.title=this.companyList.filter(item => item.value === data)[0].text;
                this.allQuillData.forEach((item, index) => {
                    if (item.id == data) {
                    this.quillData = item;
                    this.currentPage = index + 1;
                    }
                });
            },
            //返回首页按钮
            goback(){
                this.$router.push({
                    name: "PD",
                });
            },
            //左上角返回按钮
            goback1(){
                this.$router.go(-1)
            },
            // 获取pd首页列表接口
            queryDataList(typeId) {
                let obj = {
                    typeId: typeId,
                };
                pdListApi(obj).then((res) => {
                    let index = res.rows.findIndex((item) => {
                    return item.id == this.$route.params.id;
                    });
                    this.pdIndexData = res.rows;
                    this.index = index;
                    this.length = res.total;
                });
            },
            queryChapterData() {
                this.loading = true;
                let obj = {
                    courseId: this.$route.params.id,
                };
                chapterListApi(obj).then((res) => {
                    this.courseName = res.data.courseName;
                    this.companyList = res.data.chapters;
                    this.companyList.forEach((item) => {
                    item.text = item.chapterName;
                    item.value = item.id;
                    });
                    this.value = this.companyList[0].id;
                    this.title = this.companyList[0].text;
                    this.quillData = res.data.chapters[0];
                    this.allQuillData = res.data.chapters;
                    this.loading = false;
                });
            },
        },
        mounted() {
            this.queryDataList(this.$route.params.typeId);
            this.queryChapterData();
            this.queryPDF()
        },
    }
</script>

<style lang="scss" scoped>

.pd_details{
    width: 100%;
    height: 100%;
    font-size: 30px;

    .header{
        position: fixed;
        left: 0;
        top: 0;
        height: 90px;
        width: 100%;
        background-color: #4DB3B3;
        // padding: 23px 43px 10px 43px;
        padding: 10px 15px 10px 15px;
        box-sizing: border-box;
        color: #FFFFFF;
        z-index: 100;

        .plan_id{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 36px;
            font-weight: bold;
            height: 100%;

            .name1 {
                width: 78%;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .icon_top{
                width: 70px;
                font-size: 40px;
                font-weight: bold;
            }

            .right{
                width: 70px;
                text-align: center;
                .pdf_icon{
                    width: 34px;
                    height: 40px;
                }
                .text{
                    color: #F2F2F2;
                    font-size: 20px;
                    margin-top: -6px;
                    font-weight: 100;
                    transform: scale(.9,.9);
                    transform-origin: center top;
                }
            }

            .no_pdf{
                width: 70px;
                height: 100%;
            }
        }
    }

    .body{
        overflow: hidden;
        width: 100%;
        height: 100%;
        // background:#E3E3E3;

        .content{
            width: 100%;
            position: absolute;
            top: 90px;
            bottom: 110px;
            overflow-y: scroll;
            box-sizing: border-box;
            padding: 30px 32px 30px 32px;
            box-sizing: border-box;

            .top_header{
                width: 99%;
                margin: 0 auto;
                font-size: 38px;
                color: black;
                font-weight: bold;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                text-align: center;
            }

            .title{
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .select{
                    /deep/.van-cell{
                        font-size: 32px;
                        padding-left: 50px;
                    }
                    width: 85%;
                    /deep/.van-dropdown-menu__bar{
                        // height: auto;
                        height: 70px;
                        line-height: 70px;
                        background: #F2F2F2;
                        box-shadow:none;
                        width: 100%;
                        text-align: left;
                        border-radius: 10px;

                        .van-dropdown-menu__item{
                            justify-content: left;
                        }

                        .van-dropdown-menu__title{
                            padding: 0 30px;
                            width: 560px;
                            

                            .van-ellipsis{
                                color: #5F5F5F;
                            }
                        }
                        .van-dropdown-menu__title::after{
                            border-color:transparent transparent #606060 #606060;
                            margin-right: 10px;
                        }
                    }
                    /deep/.van-dropdown-item{
                        .van-cell__value{
                            flex: 0.1;
                        }
                    }
                }
                .all_num{
                    text-align: center;
                    .num {
                        color: #4EB8B8;
                        font-size: 30px;
                        font-weight: bold;
                    }
                    .text{
                        font-size: 24px;
                        color: #606060;
                    }
                }
            }

            .small_name{
                margin-top: 30px;
                width: 100%;
                // border: 1px solid white;
                font-size: 36px;
                color: black;
                font-weight: bold;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .wangEditor {
                margin-top: 30px;
                font-size: 0px;
                white-space: pre-wrap;
                padding: 0px;

                * {
                text-decoration: none !important;
                }
                /deep/ .viewHeight {
                // overflow-y: auto;
                // border: 1px solid #dfe4ed;
                // padding: 40px 30px;
                color: #606060 !important;
                // background-color: #f8f8f9;
                font-size: 36px;
                white-space: pre-wrap;
                p {
                    // line-height: 40px;
                    line-height: 1.8;
                }
                ol,
                ul {
                    padding-left: 20px;
                    padding-top: 0px;
                    margin: 0px;
                    li {
                    list-style: none !important;
                    list-style-position: inside !important; //让序号和圆点跟随

                    &::before {
                        float: none !important; //为了让ol前面的序号跟随
                    }
                    }
                }
                ul li {
                    list-style: none !important;
                }
                ol li::before {
                    float: none !important; //为了让ol前面的序号跟随
                }
                li:not(.ql-direction-rtl)::before {
                    margin-left: -1.5em;
                    margin-right: 0.3em;
                    text-align: center;
                }
                img {
                    max-width: 100%;
                }

                a {
                    color: #06c !important;
                }
                }
            }
            .ql-editor{
                overflow-y: unset;
                height: auto;
                /deep/.ql-video {
                    width: 100%;
                }
            }
        }
    }

    .next_btn{
        position: fixed;
        // bottom: 30px;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: #F2F2F2;
        height: 110px;
        // display: flex;
        .footer{
            display: flex;
            height: 100%;
            align-items: center;
        }

            .btn{
                width: 45%;
                height: 80px;
                background-color: #4FB9B8;
                margin: 0 auto;
                font-size: 32px;
                color: #FFFFFF;
            }
            .gray {
                background: #C1C1C1 !important;
                color: #F2F2F2 !important;
            }
    }
}

</style>
